import { API_PATH } from '../const/api_paths'

export const doGet = function (endpoint, data, callback = {}) {
  var headers = {
    method:   "GET",
    mode:     "cors",
    cache:    "no-cache",
    headers:  {"Content-Type": "application/json"},
    body:     JSON.stringify(data)
  }
  doFetch(endpoint, headers, callback)
}

export const doPost = (endpoint, data, callback = {}) => {
  var options = {
    method:   "POST",
    mode:     "cors",
    cache:    "no-cache",
    headers:  {"Content-Type": "application/json"},
    body:     JSON.stringify(data)
  }
  doFetch(endpoint, options, callback)
}

export const doAuthenticatedPost = (endpoint, data, callback = {}) => {
  var token = localStorage.getItem('authToken')
  var headers = new Headers()
  headers.append("Content-Type", "application/json")
  headers.append("Authorization", `Bearer ${token}`)

  var options = {
    method:   "POST",
    mode:     "cors",
    cache:    "no-cache",
    headers:  headers,
    body:     JSON.stringify(data)
  }
  doFetch(endpoint, options, callback)
}

export const doFetch = (endpoint, headers, callback) => {
  fetch(API_PATH(endpoint), headers)
  .then(res => res.json())
  .then(res=> {
    if(res.status === "ok" || res.status === "success") {
      if(callback.success) { callback.success(res) }
    } else if(res.status === "error") {
      if(callback.error) { callback.error(res) }
    }
  })
  .catch(res => {
    if(callback.catch) { callback.catch(res) }
  })
}



// const getFormData = (form_data, data, key = '') => {
//   if(data instanceof File) {
//     form_data.append(key, data)
//   } else if(data instanceof Array) {
//     data.forEach(val => getFormData(form_data, val, `${key}[]`))
//   } else if (data instanceof Object) {
//     Object.keys(data).forEach(k => getFormData(form_data, data[k], `${key}${k}`))
//   } else if(key !== '') {
//     form_data.append(key, data)
//   }
// }