import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Signin from '../../components/auth/signin'

class AuthPage extends React.Component {

  render() {
    return(
      <Layout>
        <SEO title="Home" />
        <div className="auth-page d-flex flex-column">
          <Signin />
        </div>
      </Layout>
    )
  }

}

AuthPage.propTypes = {}
export default AuthPage